import { createContext, useState} from "react";

const CurrencycodeContext = createContext();

export function CurrencyProvider({children}){
  const [item, setItem] = useState([]);


    const getcurrncyCode = (id) => {
        setItem(id)
    }
     
  
    return (
       <CurrencycodeContext.Provider value={{item, getcurrncyCode}}>{children}</CurrencycodeContext.Provider>
    );
}


export default CurrencycodeContext;