import * as React from 'react';
import './App.css';

import { useEffect, useState } from 'react';
 
// import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Routes, Route, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Result from './Result';
import Booking from './Booking';
import CardInformation from './CardInformation';
import CheapFlights from './CheapFlights';
import Footer from './Footer';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import { PostProvider } from './PostContext';
import {LoginProvider} from './LoginContext';
import { CurrencyProvider } from './CurrencycodeContext';
import { EditedCurrencyProvider } from './EditedCountryCurrCodeContext';
import MyAccount from './MyAccount';
import CommunityGuidelines from './CommunityGuidelines';
import Terms_service from './Terms_service';
import Privacy_Pocily from './Privacy_Pocily';
import CookiePolicy from './CookiePolicy';
import Search from './Search';
// import Blogs from './Blogs';
// import Post from './Post';

const App = (props) => {
  
  
 
  return (
    <div className="App">
      <div className='fseBody'>
       </div>
       <EditedCurrencyProvider>
       <CurrencyProvider>
       <LoginProvider>
       
 
      <Routes> 
      <Route path="/" exact element={<Home />} />
      <Route path="/:slug" exact element={<Home />} />
      <Route path="/search"  element={<Search />} />
      <Route path="/about-us" element={<About />}/>
      <Route path="/my-account" element={<MyAccount />}/>
      <Route path="/community-guidelines" element={<CommunityGuidelines />}/>
      <Route path="/terms-of-service" element={<Terms_service />}/>
      <Route path="/privacy-policy" element={<Privacy_Pocily />}/>
      <Route path="/cookie-policy" element={<CookiePolicy />}/>
   
      {
        // <Route path="/blog" element={<Blogs />}/>
        // <Route path="/blog/:slug" element={<Post />}/>
      }
      <Route path="/Contact-us" element={<Contact />}/>
      
      </Routes>
    
  
    </LoginProvider>
    </CurrencyProvider>
    </EditedCurrencyProvider>
    </div>
  
  );
}

export default App;
