import { createContext, useState} from "react";

const EditedCountryCurrCodeContext = createContext();

export function EditedCurrencyProvider({children}){
  const [code, setCode] = useState([]);


    const getEditedcurrncyCode = (id) => {
        setCode(id)
    }
     
  
    return (
       <EditedCountryCurrCodeContext.Provider value={{code, getEditedcurrncyCode}}>{children}</EditedCountryCurrCodeContext.Provider>
    );
}


export default EditedCountryCurrCodeContext;