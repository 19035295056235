import * as React from 'react';
import './App.css';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Typography from '@mui/material/Typography';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import CompareIcon from '@mui/icons-material/Compare';
 
import { Button } from '@mui/material';
const CardInformation = () =>{
    const theme = useTheme();
    



    return(
           

        <div className='tipsInformation '>
       
     



        <div className='container'>
        <div className='row justify-content-center mt-5 pt-3'>
        <div className='col-md-4'>
        <Card variant=" " sx={{ display: 'flex' }}>
        <Button className='infoIcon' aria-label="TravelExploreIcon">
              <TravelExploreIcon  sx={{ height: 38, width: 38, color: '#9c27b0' }} />
            </Button>
         
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h5">
             
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
            Find the best fare by comparing flights from over 1000 providers, then book with no fees
            </Typography>
          </CardContent>
           
        </Box>
        
      </Card>
        </div>
        <div className='col-md-4'>
        <Card variant=" " sx={{ display: 'flex' }}>
        <Button  className='infoIcon' aria-label="CompareIcon">
              <CompareIcon sx={{ height: 38, width: 38, color: '#4a148c' }} />
            </Button>
         
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h5">
             
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
            Compare flights from over 1000 providers, then book the cheapest, fastest or greenest flight with no fees.
            </Typography>
          </CardContent>
           
        </Box>
        
      </Card>
        </div>
        <div className='col-md-4'>
        <Card variant="" sx={{ display: 'flex' }}>
        <Button className='infoIcon' aria-label="CoronavirusIcon">
              <CoronavirusIcon sx={{ height: 38, width: 38, color: '#b71c1c' }} />
            </Button>
         
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h5">
             
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
            Travel with confidence – see the COVID-19 safety measures airlines have in place to keep you safe
            </Typography>
          </CardContent>
           
        </Box>
        
      </Card>
        </div>
        </div>
        </div>
        </div>
    )

}


export default CardInformation;
