import React from 'react';
 
import './Post.css';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Blogsheader from "./Blogsheader";
// import { useParams } from "react-router-dom";
import Footer from "./Footer";
var axios = require("axios");

const Privacy_Pocily = () => {
    const [pages, setPages] = useState();

    useEffect(()=> {
        getpageDate();
    }, [])

    const getpageDate = async() => {
        const PageData = await axios.get(
            "https://wundrfly.com/blog/wp-json/wp/v2/pages/3899"
          );
          console.log(PageData);
          setPages(PageData.data);
    }



  return (
    <div>
      <Blogsheader/>
      <div className='parentContainer'>
      
    
      <div className='container wp-pages'>
      
    
     {
        pages ? <div>
         <p
                dangerouslySetInnerHTML={{
                  __html: pages.content.rendered.replace(/&#8217;/g, "'").replace(/&#8220;/g, '"').replace(/&amp;/g, "&").replace(/&#8211;/g, "–"),
                }}
              ></p>
        </div> : ''
     }
     </div>
     </div>
      <Footer />
    </div>
  )
}

export default Privacy_Pocily
