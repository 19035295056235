import * as React from 'react';
import './App.css';
import { Link } from 'react-router-dom';
const baseURL = '/transport_deeplink/4.0/US/en-GB/USD/finn/1';
const originPlaceId = '12126';
const destinationPlaceId = '15130';
const departureDateTime = '2024-05-20T06:00:00';
const returnDateTime = '2024-05-20T07:00:00';
const cabinClass = 'economy';
const passengerCount = 1;
const ticketPrice = 52.10;

const deeplinkURL = `${baseURL}/${originPlaceId}.${destinationPlaceId}.${departureDateTime}/air/airli/flights?itinerary=flight|-32317|431|${originPlaceId}|${departureDateTime}|${destinationPlaceId}|${returnDateTime}|60|ZNY0T9BE|Z|ESLCAMP11&carriers=-32317&operators=-32317&passengers=${passengerCount}&channel=website&cabin_class=${cabinClass}&ticket_price=${ticketPrice}`;





const Footer = () => {
   //  alert(deeplinkURL)
   // const handleBookingClick = () => {
   //    window.location.href = deeplinkURL; // Redirect user to the deeplink URL
   //  };




return(
   <div className='footer pb-4 pt-4 '>
      {/* <button onClick={handleBookingClick}>
      Book Now
    </button> */}
   <div className='container-fluid  '>
   <div className='container'>
   <div className='flex-cs justify-content-center'>
   <div className='m-2'>
   <a><Link to="/about-us">About us</Link></a>
   </div>
   <div className='m-2'>
   
   <a href="https://wundrfly.com/blog">Blogs </a>
   </div>
   <div className='m-2'>
   <a><Link to="/cookie-policy">Cookie policy</Link></a>
   </div>
   <div className='m-2'>
   <a><Link to="/terms-of-service">Terms of service</Link></a>
   </div>
   <div className='m-2'>
   <a><Link to="/privacy-policy">Privacy Policy</Link></a>
   </div>
   <div className='m-2'>
   <a><Link to="/community-guidelines">Community Guidelines</Link></a>
   </div>
   <div className='m-2'>
   <a href='https://wundrfly.com/contact-us'>Contact us</a>
   </div>
   </div>
   <div className='text-center'>  
   
   <article> Plan your travel with FlightSearch. Compare cheap flights now</article>
   <small>© Wundrfly.com - 2023</small>
   </div>
   </div>
   </div>
   </div>
)
}
export default Footer;