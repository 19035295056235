import * as React from "react";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import AirplanemodeActiveRoundedIcon from "@mui/icons-material/AirplanemodeActiveRounded";
import Button from '@mui/material/Button';
import './Result.css';
import { useState, useEffect, useRef } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



var axios = require("axios");
// import Fares from './Fares';

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const FlightsResults = (props) => {
  const [open, setOpen] = useState(false);
  const [MergedWithFaresFinall, setMergedWithFaresFinall] = useState([]);
  const [RoundTripMergedWithFares, setRoundTripMergedWithFares] = useState([]);
  const [arrayResult, setarryResult] = useState([]);
  const [currency_code, setCurrency_code] = useState([]);
  const [filtercurrency, setFiltercurrency] = useState([]);
  const [Loader, setLoader] = useState(60);
  const [BoolforLoading, setBoolforLoading] = useState(true);
  const [sortBySelected, setSortBySelected] = useState();
  const [CurrencySymbols, setCurrencySymbols] = useState([])
  const [DetailID, setDetailID] = useState();
  const [DetailID2, setDetailID2] = useState();
  const [MergedDetails, setMergedDetails] = useState();






  const sortValue = useRef();
  let TotalResShowCheck = false;

  const results = props.results;
  // console.log(results);
  const [AllRes, setAllRes] = useState(results);
  const sortByResultsValue = props.sortResult;

  const faresFilter = props.priceFilter;
  let valueFilterStaus = props.valueFilterStaus;

  let resultFares = results.fares;

  let resultLegs = results.legs;
  //console.log('resultLegs.length', resultLegs.length, 'resultFares.length', resultFares.length, 'results.faresCount', results.faresCount);
  
  // console.log("resultLegs", resultLegs);
  // console.log("resultTrips", resultTrips);
  const flightPlaces = props.flightPlaces;
  const stopsResults = props.stopsResults;
  const durationFilter = props.durationFilter;
  let checkedAirlines = props.checkedAirlines;
  let unCheckedAirlines = props.unCheckedAirlines;
  let isfilterStatus = props.isfilterStatus;
 
  //console.log(checkedAirlines, unCheckedAirlines, isfilterStatus);
  let direct, onestop, twostops;
  let resultLegs1 = [];
  let resultLegs2 = [];
  let resultLegs3 = [];
  let bolean = "false";
  let truefalseMergedFilterAirlines = [];
  if(sortByResultsValue === "Best"){
   // console.log("best")
   // resultLegs  = resultLegs.filter((item) => item.stopoverCode == "DIRECT");
    resultLegs =  resultLegs.filter((item) => item.stopoverCode == "DIRECT" || "ONE_STOP");
   // console.log((faresFilter[1]))
   // console.log(faresFilter[1] - (faresFilter[1] * .50));
    resultFares = resultFares.filter((fare) => fare.price.totalAmount < faresFilter[1] - (faresFilter[1] * .50));
   // resultFares = resultFares.sort((a, b) => (a.price.totalAmount > b.price.totalAmount ? 1 : -1));
    //console.log(resultFares)
   // resultLegs =  resultLegs.filter((item) => item.stopoverCode == "MORE_THAN_ONE_STOP");
  }   

  if (valueFilterStaus) {
    TotalResShowCheck = true;
    resultFares = resultFares.filter((fare) => fare.price.totalAmount >= faresFilter[0] && fare.price.totalAmount <= faresFilter[1]);
    //   console.log(resultFares)
  }
  if (stopsResults.DIRECT == true) {
    TotalResShowCheck = true;
    direct = "DIRECT";
    resultLegs1 = resultLegs.filter((item) => item.stopoverCode == direct);
  }

  if (stopsResults.ONE_STOP == true) {
    TotalResShowCheck = true;
    onestop = "ONE_STOP";
    resultLegs2 = resultLegs.filter(
      (resultLegs) => resultLegs.stopoverCode == onestop
    );
    //console.log(resultLegs);
  }

  if (stopsResults.TWO_STOPS == true) {
    TotalResShowCheck = true;
    twostops = "MORE_THAN_ONE_STOP";
    resultLegs3 = resultLegs.filter(
      (resultLegs) => resultLegs.stopoverCode == twostops
    );
  }

  if (
    stopsResults.TWO_STOPS == true ||
    stopsResults.ONE_STOP == true ||
    stopsResults.DIRECT == true
  ) {
    // TotalResShow = resultLegs.length;
    TotalResShowCheck = true;
    resultLegs = [...resultLegs1, ...resultLegs2, ...resultLegs3];
  } else {
    //  TotalResShow = 20;
    TotalResShowCheck = false;
    resultLegs = resultLegs;
  }
  resultLegs = resultLegs.filter(
    (leg) =>
      leg.durationMinutes >= durationFilter[0] &&
      leg.durationMinutes <= durationFilter[1]
  );
  //console.log(resultLegs)
  //console.log(direct, onestop, twostops)
  if (isfilterStatus) {
    // console.log("hello its activated")
    const isSameUser = (a, b) => a.code === b.code && a.name === b.name;
    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(
        (leftValue) =>
          !right.some((rightValue) => compareFunction(leftValue, rightValue))
      );

    const onlyInA = onlyInLeft(checkedAirlines, unCheckedAirlines, isSameUser);
    const onlyInB = onlyInLeft(unCheckedAirlines, checkedAirlines, isSameUser);
    truefalseMergedFilterAirlines = [...onlyInA, ...onlyInB];
    if (truefalseMergedFilterAirlines.length === 0) {
      props.passAirlinefilterlengthStatus(false);
    } else {
      props.passAirlinefilterlengthStatus(true);
    }
    if (truefalseMergedFilterAirlines.length >= 1) {
      // TotalResShow = resultLegs.length;
      TotalResShowCheck = true;
 
      resultLegs = resultLegs.filter((x) =>
        truefalseMergedFilterAirlines.some((y) => {
          if (x.airlineCodes[0] === y.code) {
          

            let checkRes = Object.assign(x, y);
            //  console.log("NewObj insideIfCondition => ", NewObj)

            return checkRes;
          }
        })
      );
    } else {
      resultLegs = resultLegs;
      //   TotalResShow = 20;
      TotalResShowCheck = false;
    }
  }
 
   const getSumbolFunction = async () => {
    try {
      const response = await fetch('/currencySymbols.json');
      const data = await response.json();
      // console.log(data);
      setCurrencySymbols(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(()=> {
      getSumbolFunction();
   
  },[]);
 
  let triptype = flightPlaces.triptype;
  console.log(triptype)
 
   
  useEffect(() => {
    if (TotalResShowCheck) {
      // alert(TotalResShowCheck);
      setLoader(results.count);
    } else {
      setLoader(50);
    }
  }, [TotalResShowCheck]);

  const loaderFunction = (event) => {
    setBoolforLoading(false);
    setTimeout(() => {
      setBoolforLoading(true);
      setLoader(Loader + 20);
    }, 10);
  };
  // cheapest
//   function comparator(a, b) {
    
//     if (a.dataset.indexvalue < b.dataset.indexvalue)       
//         return -1;
//     if (a.dataset.indexvalue > b.dataset.indexvalue)
//         return 1;
//     return 0;
// }
function comparatorFast(a, b) {
 // console.log(a.dataset.indexfast, b.dataset.indexfast)
  if (a.dataset.indexfast < b.dataset.indexfast)
     
      return -1;
  if (a.dataset.indexfast > b.dataset.indexfast)
      return 1;
  return 0;
}
function comparatorBest(a, b) {
  //    console.log(a.dataset.indexbest, b.dataset.indexbest)
  if (a.dataset.indexbest < b.dataset.indexbest)
     
      return -1;
  if (a.dataset.indexbest > b.dataset.indexbest)
      return 1;
  return 0;
}
  const SortDataBest = (value) => {
    setSortBySelected(value);
    var indexes = document.querySelectorAll("[data-indexbest]");
    var indexes1 = document.querySelectorAll("[data-indexfast]");
   // console.log(indexes, indexes1);
    var indexesArray = Array.from(indexes);
    var indexesArray1 = Array.from(indexes1);
   // console.log(indexesArray);
   // console.log(indexesArray1);
   let sorted = indexesArray.sort(comparatorBest);  
   let sorted1 = indexesArray1.sort(comparatorFast); 
  // console.log(sorted)
   sorted.forEach(e =>
    document.querySelector("#sortOrder").appendChild(e));
    sorted1.forEach(e =>
      document.querySelector("#sortOrder").appendChild(e));
  }
  const SortDataCheapest = (value) => {
    setSortBySelected(value);
    //var indexes = document.querySelectorAll("[data-indexvalue]");
    var sortableList = document.getElementById("sortOrder");
    var listItems = Array.from(sortableList.querySelectorAll(".RowResultEach"));
    listItems.sort((a, b) => {
      const aValue = parseInt(a.getAttribute("data-indexvalue"));
      const bValue = parseInt(b.getAttribute("data-indexvalue"));
    
      return aValue - bValue;
    });

    // var indexesArray = Array.from(indexes);
   // console.log(indexesArray);
  //  let sorted = indexesArray.sort(comparator);  
   // console.log(sorted)
   listItems.forEach(e =>
    document.querySelector("#sortOrder").appendChild(e));
  }
  const SortDataFastest = (value) => {
    setSortBySelected(value);
    var indexes = document.querySelectorAll("[data-indexfast]");
   // console.log(indexes);
    var indexesArray = Array.from(indexes);
   // console.log(indexesArray);
   let sorted = indexesArray.sort(comparatorFast);  
  // console.log(sorted)
   sorted.forEach(e =>
    document.querySelector("#sortOrder").appendChild(e));
  }
 
//  console.log(AllRes);
 
 const getFlightDetails = (id, id2)=> {
  // alert(id)
  console.log(id, id2)
  setOpen(true);
  setDetailID(id)
  setDetailID2(id2)
  var mergedD = id + '|' + id2;
  console.log(mergedD);
  setMergedDetails(mergedD) 
  }
  const handleClose = () => {
    setOpen(false);
  };
  
 
 

  return (
    <div>
    <div className="text-end">
    <div className="dropdown sortbyDropDown">
  <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Sort by:  {sortBySelected}
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li onClick={() => SortDataBest('Best')}><a className="dropdown-item">Best</a></li>
    <li onClick={() => SortDataCheapest('Cheapest')}><a className="dropdown-item">Cheapest</a></li>
    <li onClick={() => SortDataFastest('Fastest')}><a className="dropdown-item">Fastest</a></li>
  </ul>
  </div>
    </div>
 
      {triptype == "onewaytrip" ? (
        <div id="sortOrder">
        {
         AllRes ? AllRes.legs.map((item, index)=> {
          //  console.log(item, index)
          return (
                    <div className="row RowResultEach mt-3 mb-3">
                      <table className="tableResult">
                        <tbody>
                         
                            <tr>
                              {
                                AllRes.carriers.map((carriersItem, carriersindex)=> {
                                  if((item.operating_carrier_ids[0] === carriersItem.id)){
                                    return <td> <img className="airlineLogo" src={"https://pics.avs.io/150/50/" + carriersItem.display_code + ".png" } ></img> </td>
                                  }
                                })
                              }
                            
                            
                            
                            <td> {item.departure.slice(11, -3)}  <small style={{ fontSize: "12px", display: "block" }} > ({item.departure.slice(0, 10)}) </small>
                            {
                              AllRes.places.map((place, placeIndex)=> {
                                if(place.id === item.origin_place_id){
                                  return(
                                    <span key={placeIndex} className="d-block cityFrom">
                                {place.display_code}
                              </span>
                                  )
                                }
                                
                              })
                            }
                              
                             
                              </td>
                            <td className="tableposi"> <span className="hourblock">{Math.floor(item.duration/60)}h {item.duration%60}m</span> -------------------- <span className="flightIcon"> <AirplanemodeActiveRoundedIcon   sx={{fontSize: "12px", flexShrink: 0,  lineHeight: 0,   color: "rgba(72, 14, 207, 0.55)"}} /> </span> <span className="stops">{item.stop_count === 0 ? "DIRECT": ''}{item.stop_count === 1 ? 'ONE_STOP'  : ""}{item.stop_count >= 2 ? 'MORE_THAN_ONE_STOP'  : ""}</span>  </td>
                            <td>{item.arrival.slice(11, -3)} <small style={{ fontSize: "12px", display: "block" }}> ({item.arrival.slice(0, 10)}) </small>
                            {
                              AllRes.places.map((place, placeIndex)=> {
                                if(place.id === item.destination_place_id){
                                  return(
                                    <span key={placeIndex} className="d-block cityTo">
                                {place.display_code}
                              </span>
                                  )
                                }
                                
                              })
                            }
                            
                          </td>   
                              
                          <td rowSpan="2">
                            {
                              AllRes.itineraries.map((itiner, itinerIndex) => {
                                if(itiner.leg_ids[0] === item.id){
                                  return (<><span className="deal" id={"getattr" + itinerIndex} >  <span> {itiner.pricing_options.length > 1 ? itiner.pricing_options.length + " deals from" : <span> {
                                    AllRes.agents.map((agentItem, agentIndex) => {
                                      if(itiner.pricing_options[0].agent_ids[0] === agentItem.id){
                                        return <h4 style={{fontSize: '18px', fontWeight: 'bold', color: 'rgb(91, 38, 216)'}}>{agentItem.name}</h4>
                                      }
                                    })
                                    } </span>}   </span>  </span>
                                    <span className="perHead" id="priceSort"> 
                                    {
                                          CurrencySymbols.map((sym, symIndex) => {
                                             if(AllRes.query.currency === sym.code){
                                              //  console.log(AllRes.query.currency)
                                              return <span className="currencyCode">{sym.symbol}</span>
                                             }
                                          })
  
                                    } {itiner.cheapest_price.amount ? itiner.cheapest_price.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : itiner.cheapest_price.amount}</span>
                                    </>)
                                }
                     
                                
                              })
                            }
                          
                            
                            <Button variant="contained" onClick={() => getFlightDetails(item.id)} className="mt-3 mb-1 clickeffect" sx={{fontSize: "14px",textTransform: "capitalize",background: "#480ecf"}}> View Deals
                              <ArrowForwardRoundedIcon
                                sx={{ fontSize: "16px" }}
                              />
                            </Button>
                            </td>
                            </tr>
                          
                        </tbody>
                      </table>
                      <div className="emission"> </div>
                      </div>
          )
                          
                    }) : "Loading"
                   }
              
        </div>
      ) : (
        <div id="sortOrder">
          
        </div>
      )}

       {/* ROUND TRIP START FS*/}
       {triptype == "roundtrip" ? (
        <div id="sortOrder" className="RoundTripDiv">
        {
         AllRes ? AllRes.itineraries.map((RoundItineraries, index)=> {
        
        
          // console.log(item.segment_ids[0] === item.segment_ids[1])
          return (
                    <div className="row RowResultEach mt-3 mb-3">
                      <table className="tableResult">
                        <tbody>
                         
                            {
                              AllRes.legs.map((item, indexLegs) => {
                                 if(RoundItineraries.leg_ids[0] === item.id){
                                  // console.log(RoundItineraries.leg_ids[0])
                                  // fareIDsOne = [RoundItineraries.leg_ids[0], ...fareIDsOne]
                                  return (
<tr>
                            {
                                AllRes.carriers.map((carriersItem, carriersindex)=> {
                                  if((item.operating_carrier_ids[0] === carriersItem.id)){
                                    
                                    return <td> <img className="airlineLogo" src={"https://pics.avs.io/150/50/" + carriersItem.display_code + ".png" } ></img> </td>
                                  }  
                                })
                              }
                            
                            
                            
                            <td> {item.departure.slice(11, -3)}  <small style={{ fontSize: "12px", display: "block" }} > ({item.departure.slice(0, 10)}) </small>
                            {
                              AllRes.places.map((place, placeIndex)=> {
                                if(place.id === item.origin_place_id){
                                  return(
                                    <span key={placeIndex} className="d-block cityFrom">
                                {place.display_code}
                              </span>
                                  )
                                }
                                
                              })
                            }
                              
                             
                              </td>
                            <td className="tableposi"> <span className="hourblock">{Math.floor(item.duration/60)}h {item.duration%60}m</span> -------------------- <span className="flightIcon"> <AirplanemodeActiveRoundedIcon   sx={{fontSize: "12px", flexShrink: 0,  lineHeight: 0,   color: "rgba(72, 14, 207, 0.55)"}} /> </span> <span className="stops">{item.stop_count === 0 ? "DIRECT": ''}{item.stop_count === 1 ? 'ONE_STOP'  : ""}{item.stop_count >= 2 ? 'MORE_THAN_ONE_STOP'  : ""}</span>  </td>
                            <td>{item.arrival.slice(11, -3)} <small style={{ fontSize: "12px", display: "block" }}> ({item.arrival.slice(0, 10)}) </small>
                            {
                              AllRes.places.map((place, placeIndex)=> {
                                if(place.id === item.destination_place_id){
                                  return(
                                    <span key={placeIndex} className="d-block cityTo">
                                {place.display_code}
                              </span>
                                  )
                                }
                                
                              })
                            }
                            
                          </td> 

                          
                            
                              
                          {/* <td rowSpan="4">
                            {
                              AllRes.itineraries.map((itiner, itinerIndex) => {
                                  // console.log(itiner.leg_ids[0] === item.id, 'LEG0')
                              //  console.log(itiner.leg_ids[1] === item.id, 'LEG1');
                              // console.log((itiner.leg_ids[0] === item.id) && (itiner.leg_ids[1] === item.id))
                                if(itiner.leg_ids[0] === item.id){
                                  
                                  return (<><span className="deal" id={"getattr" + itinerIndex} >  <span> {itiner.pricing_options.length > 1 ? itiner.pricing_options.length + " deals from" : <span> {
                                    AllRes.agents.map((agentItem, agentIndex) => {
                                      if(itiner.pricing_options[0].agent_ids[0] === agentItem.id){
                                        return <h4 style={{fontSize: '18px', fontWeight: 'bold', color: 'rgb(91, 38, 216)'}}>{agentItem.name}</h4>
                                      }
                                    })
                                    } </span>}   </span>  </span>
                                    <span className="perHead" id="priceSort"> 
                                    {
                                          CurrencySymbols.map((sym, symIndex) => {
                                             if(AllRes.query.currency === sym.code){
                                              //  console.log(AllRes.query.currency)
                                              return <span className="currencyCode">{sym.symbol}</span>
                                             }
                                          })
  
                                    } {itiner.cheapest_price.amount ? itiner.cheapest_price.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : itiner.cheapest_price.amount}</span>
                                    </>)
                                }
                     
                                
                              })
                            }
                          
                            
                            <Button variant="contained" onClick={() => getFlightDetails(item.id)} className="mt-3 mb-1 clickeffect" sx={{fontSize: "14px",textTransform: "capitalize",background: "#480ecf"}}> View Deals
                              <ArrowForwardRoundedIcon
                                sx={{ fontSize: "16px" }}
                              />
                            </Button>
                            </td> */}
                            </tr>
                                  )
                                 }
                               
                              })
                            }

{
                              AllRes.legs.map((item, indexLegs) => {
                                 if(RoundItineraries.leg_ids[1] === item.id){
                               //  console.log(item)
                                  return (
<tr>
                            {
                                AllRes.carriers.map((carriersItem, carriersindex)=> {
                                  
                                  if((item.operating_carrier_ids[0] === carriersItem.id)){
                              //  console.log(carriersItem);
                                    return <td> <img className="airlineLogo" src={"https://pics.avs.io/150/50/" + carriersItem.display_code + ".png" } ></img> </td>
                                    
                                  }   
                                })
                              }
                            
                            
                            
                            <td> {item.departure.slice(11, -3)}  <small style={{ fontSize: "12px", display: "block" }} > ({item.departure.slice(0, 10)}) </small>
                            {
                              AllRes.places.map((place, placeIndex)=> {
                                if(place.id === item.origin_place_id){
                                  return(
                                    <span key={placeIndex} className="d-block cityFrom">
                                {place.display_code}
                              </span>
                                  )
                                }
                                
                              })
                            }
                              
                             
                              </td>
                            <td className="tableposi"> <span className="hourblock">{Math.floor(item.duration/60)}h {item.duration%60}m</span> -------------------- <span className="flightIcon"> <AirplanemodeActiveRoundedIcon   sx={{fontSize: "12px", flexShrink: 0,  lineHeight: 0,   color: "rgba(72, 14, 207, 0.55)"}} /> </span> <span className="stops">{item.stop_count === 0 ? "DIRECT": ''}{item.stop_count === 1 ? 'ONE_STOP'  : ""}{item.stop_count >= 2 ? 'MORE_THAN_ONE_STOP'  : ""}</span>  </td>
                            <td>{item.arrival.slice(11, -3)} <small style={{ fontSize: "12px", display: "block" }}> ({item.arrival.slice(0, 10)}) </small>
                            {
                              AllRes.places.map((place, placeIndex)=> {
                                if(place.id === item.destination_place_id){
                                  return(
                                    <span key={placeIndex} className="d-block cityTo">
                                {place.display_code}
                              </span>
                                  )
                                }
                                
                              })
                            }
                            
                          </td> 

                          
                            
                              
                          
                            </tr>
                                  )
                                 }
                               
                              })
                            }
                             
                            
                             
                          
                        </tbody>
                      </table>
                      {/* <div className="emission"> </div> */}
                      <div className="viewDealSection">
                      <div>
                            {
                              AllRes.legs.map((item) => {
                                  // console.log(itiner.leg_ids[0] === item.id, 'LEG0')
                              //  console.log(itiner.leg_ids[1] === item.id, 'LEG1');
                              // console.log((itiner.leg_ids[0] === item.id) && (itiner.leg_ids[1] === item.id))
                                if(RoundItineraries.leg_ids[0] === item.id){
                                  
                                  return (<><span className="deal" id={"getattr" + index} >  <span> {RoundItineraries.pricing_options.length > 1 ? RoundItineraries.pricing_options.length + " deals from" : <span> {
                                    AllRes.agents.map((agentItem, agentIndex) => {
                                      if(RoundItineraries.pricing_options[0].agent_ids[0] === agentItem.id){
                                        return <h4 style={{fontSize: '18px', fontWeight: 'bold', color: 'rgb(91, 38, 216)'}}>{agentItem.name}</h4>
                                      }
                                    })
                                    } </span>}   </span>  </span>
                                    <span className="perHead" id="priceSort"> 
                                    {
                                          CurrencySymbols.map((sym, symIndex) => {
                                             if(AllRes.query.currency === sym.code){
                                              //  console.log(AllRes.query.currency)
                                              return <span className="currencyCode">{sym.symbol}</span>
                                             }
                                          })
  
                                    } {RoundItineraries.cheapest_price.amount ? RoundItineraries.cheapest_price.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : RoundItineraries.cheapest_price.amount}</span>
                                  <Button variant="contained" onClick={() => getFlightDetails(RoundItineraries.leg_ids[0], RoundItineraries.leg_ids[1])} className="mt-3 mb-1 clickeffect" sx={{fontSize: "14px",textTransform: "capitalize",background: "#480ecf"}}> View Deals
                                  <ArrowForwardRoundedIcon
                                    sx={{ fontSize: "16px" }}
                                  />
                                </Button>
                                    </>)

                                   
 
                                }
                                  
                                  
                                // }
                     
                                
                              })
                            }
                          
                            
                            
                            </div>

                      </div>
                      </div>
                      
          )
                          
                    }) : "Loading"
                   }
              
        </div>
      ) : (
        <div id="sortOrder">
          
        </div>
      )}






       {/* ROUND TRIP ENDS FS*/}
       

      <div className="m-0"></div>
      <Dialog
     open={open}
     maxWidth = "lg"
     fullWidth = 'true'
     aria-labelledby="alert-dialog-title"
     aria-describedby="alert-dialog-description"
   >
        <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">Flight Details</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </div>
      <div className="modal-body">
        <div className="row mb-4 bxShad">
          <div className="col-md-6 flightDetailspopupTwowayTrip">
            <h3 className="bottomLine">Outbound</h3>
            <div className="col-md-12">
            {
                AllRes.legs.map((legItem, index)=> {
                  if(DetailID === legItem.id)
                   return(<>
                   <div className="d-flex">
                <div className="departHeading" style={{ width: '50%' }}>Depart: <span className="dateTimeDepart">{legItem.departure.slice(0, 10)}</span></div>
                <div className="departHeading" style={{ width: '50%', textAlign: 'end' }}>Arrives: <span className="dateTimeDepart">{legItem.arrival.slice(0, 10)}</span></div>
              </div>
              <table className="tableResult" style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '33%', textAlign: 'center' }}>
                      <small style={{ fontSize: '25px', display: 'block', fontWeight: '300', marginTop: '16px', marginBottom: '5px', color: 'rgb(102, 102, 102)' }}>{legItem.departure.slice(11, -3)}</small>
                      { AllRes.places.map((place, placeIndex)=> {
                         if(place.id === legItem.origin_place_id){
                             return (<>
                             <span className="boldCode">{place.display_code} </span>
                             <span> {place.name} </span>
                             </>)
                         }
                      })


                        
                      }
                    </td>
                    <td className="tableposi" style={{ width: '33%', textAlign: 'center' }}>
                      <span className="hourblock">{Math.floor(legItem.duration/60)}h {legItem.duration%60}m </span>
                      <span className="BigDashs"></span>
                      <span className="flightIcon">
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1aybvsk" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AirplanemodeActiveRoundedIcon">
                          <path d="M21.48 13.7 13.5 9V3.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5V9l-7.98 4.7c-.32.18-.52.53-.52.9 0 .7.67 1.2 1.34 1.01l7.16-2.1V19l-2.26 1.35c-.15.09-.24.26-.24.43v.58c0 .33.31.57.62.49l2.92-.73L12 21l.38.09.42.11 1.9.48.67.17c.32.08.62-.16.62-.49v-.58c0-.18-.09-.34-.24-.43L13.5 19v-5.5l7.16 2.1c.67.2 1.34-.3 1.34-1 0-.37-.2-.72-.52-.9z"></path>
                        </svg>
                      </span>
                      <span className="stops"> {legItem.stop_count === 0 ? "DIRECT": ''}{legItem.stop_count === 1 ? 'ONE_STOP'  : ""}{legItem.stop_count >= 2 ? 'MORE_THAN_ONE_STOP'  : ""} </span>
                    </td>
                    <td style={{ width: '33%', textAlign: 'center' }}>
                      <small style={{ fontSize: '25px', display: 'block', fontWeight: '300', marginTop: '16px', marginBottom: '5px', color: 'rgb(102, 102, 102)' }}>{legItem.arrival.slice(11, -3)}</small>
                      { AllRes.places.map((place, placeIndex)=> {
                         if(place.id === legItem.destination_place_id){
                             return (<>
                             <span className="boldCode">{place.display_code} </span>
                             <span> {place.name} </span>
                             </>)
                         }
                      })


                        
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
                   </>)
                })
              }
              
            </div>
          </div>
          <div className="col-md-6 flightDetailspopupTwowayTrip">
            <h3 className="bottomLine">Return</h3>
            <div className="col-md-12">
            {
                AllRes.legs.map((legItem, index)=> {
                  if(DetailID2 === legItem.id)
                   return(<>
                   <div className="d-flex">
                <div className="departHeading" style={{ width: '50%' }}>Depart: <span className="dateTimeDepart">{legItem.departure.slice(0, 10)}</span></div>
                <div className="departHeading" style={{ width: '50%', textAlign: 'end' }}>Arrives: <span className="dateTimeDepart">{legItem.arrival.slice(0, 10)}</span></div>
              </div>
              <table className="tableResult" style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '33%', textAlign: 'center' }}>
                      <small style={{ fontSize: '25px', display: 'block', fontWeight: '300', marginTop: '16px', marginBottom: '5px', color: 'rgb(102, 102, 102)' }}>{legItem.departure.slice(11, -3)}</small>
                      { AllRes.places.map((place, placeIndex)=> {
                         if(place.id === legItem.origin_place_id){
                             return (<>
                             <span className="boldCode">{place.display_code} </span>
                             <span> {place.name} </span>
                             </>)
                         }
                      })


                        
                      }
                    </td>
                    <td className="tableposi" style={{ width: '33%', textAlign: 'center' }}>
                      <span className="hourblock">{Math.floor(legItem.duration/60)}h {legItem.duration%60}m </span>
                      <span className="BigDashs"></span>
                      <span className="flightIcon">
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1aybvsk" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AirplanemodeActiveRoundedIcon">
                          <path d="M21.48 13.7 13.5 9V3.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5V9l-7.98 4.7c-.32.18-.52.53-.52.9 0 .7.67 1.2 1.34 1.01l7.16-2.1V19l-2.26 1.35c-.15.09-.24.26-.24.43v.58c0 .33.31.57.62.49l2.92-.73L12 21l.38.09.42.11 1.9.48.67.17c.32.08.62-.16.62-.49v-.58c0-.18-.09-.34-.24-.43L13.5 19v-5.5l7.16 2.1c.67.2 1.34-.3 1.34-1 0-.37-.2-.72-.52-.9z"></path>
                        </svg>
                      </span>
                      <span className="stops"> {legItem.stop_count === 0 ? "DIRECT": ''}{legItem.stop_count === 1 ? 'ONE_STOP'  : ""}{legItem.stop_count >= 2 ? 'MORE_THAN_ONE_STOP'  : ""} </span>
                    </td>
                    <td style={{ width: '33%', textAlign: 'center' }}>
                      <small style={{ fontSize: '25px', display: 'block', fontWeight: '300', marginTop: '16px', marginBottom: '5px', color: 'rgb(102, 102, 102)' }}>{legItem.arrival.slice(11, -3)}</small>
                      { AllRes.places.map((place, placeIndex)=> {
                         if(place.id === legItem.destination_place_id){
                             return (<>
                             <span className="boldCode">{place.display_code} </span>
                             <span> {place.name} </span>
                             </>)
                         }
                      })


                        
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
                   </>)
                })
              }
            </div>
          </div>
        </div>
        {triptype === 'onewaytrip' ?  <>
        <h5 className="modal-title">Fare options</h5>
        <div className="faresDetailsCard">
          {
            AllRes.itineraries.map((itiner, itinerIndex)=> {
              if(DetailID === itiner.leg_ids[0]){
                return(<>
                
              {
                 AllRes.agents.map((agentItem, agentIndex) => {
                   
                  return (<> 
                  {
                    itiner.pricing_options.map((priceItem, Pidx)=> {

                      if(priceItem.agent_ids[0] === agentItem.id){
                        return (<>
                          <div className="row align-items-center" style={{ margin: 0, padding: '10px 0px 10px 0px', width: '100%', borderBottom: '1px solid #eee' }}>
                <div className="col-md-6"> 
                          <h4 style={{fontSize: '21px', fontWeight: 'bold', color: 'rgb(91, 38, 216)'}}>{agentItem.name}</h4>
                          </div>
            <div className="col-md-6 text-end">
              <span>
                {CurrencySymbols.map((sym, symIndex) => {
                                             if(AllRes.query.currency === sym.code){
                                              //  console.log(AllRes.query.currency)
                                              return <small className="currencyCode">{priceItem.price.amount? sym.symbol: ""}</small>
                                             }
                                          })
  
                                    }
                                  
                <span className="totalAmount"> {priceItem.price.amount ? priceItem.price.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : priceItem.price.amount} </span>
                <a href={'https://www.skyscanner.com'+priceItem.items[0].url} target="_blank" className="marginLeft">
                  <Button variant="contained" className="clickeffect" tabIndex="0" type="button" style={{ backgroundColor: 'rgb(3, 13, 78)', borderRadius: '0px 20px 20px 0px' }}>
                    Select Deal
                    <ArrowForwardRoundedIcon
                                sx={{ fontSize: "16px" }}
                              />
                     
                  </Button>
                </a>
              </span>
            </div>
          </div>
                          </>)
                      }
                          
                    })
                  }
                  </>)
                 
                         

                   
                 })
              }
              
            
                </>)
              }
            })
          }
        </div>
         </>: <><h5 className="modal-title">Fare options</h5>
        <div className="faresDetailsCard">
          {
            AllRes.itineraries.map((itiner, itinerIndex)=> {
              if(MergedDetails === itiner.id){
                return(<>
                
              {
                 AllRes.agents.map((agentItem, agentIndex) => {
                   
                  return (<> 
                  {
                    itiner.pricing_options.map((priceItem, Pidx)=> {

                      if(priceItem.agent_ids[0] === agentItem.id){
                        return (<>
                          <div className="row align-items-center" style={{ margin: 0, padding: '10px 0px 10px 0px', width: '100%', borderBottom: '1px solid #eee' }}>
                <div className="col-md-6"> 
                          <h4 style={{fontSize: '21px', fontWeight: 'bold', color: 'rgb(91, 38, 216)'}}>{agentItem.name}</h4>
                          </div>
            <div className="col-md-6 text-end">
              <span>
                {CurrencySymbols.map((sym, symIndex) => {
                                             if(AllRes.query.currency === sym.code){
                                              //  console.log(AllRes.query.currency)
                                              return <small className="currencyCode">{priceItem.price.amount? sym.symbol: ""}</small>
                                             }
                                          })
  
                                    }
                                  
                <span className="totalAmount"> {priceItem.price.amount ? priceItem.price.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : priceItem.price.amount} </span>
                <a href={'https://www.skyscanner.com'+priceItem.items[0].url} target="_blank" className="marginLeft">
                  <Button variant="contained" className="clickeffect" tabIndex="0" type="button" style={{ backgroundColor: 'rgb(3, 13, 78)', borderRadius: '0px 20px 20px 0px' }}>
                    Select Deal
                    <ArrowForwardRoundedIcon
                                sx={{ fontSize: "16px" }}
                              />
                     
                  </Button>
                </a>
              </span>
            </div>
          </div>
                          </>)
                      }
                          
                    })
                  }
                  </>)
                 
                         

                   
                 })
              }
              
            
                </>)
              }
            })
          }
        </div></>}
      </div>
    </div>
   </Dialog>
    </div>
  );
};
export default FlightsResults;
