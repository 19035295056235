import * as React from 'react';
import { useEffect, useState } from 'react';
import './App.css';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import Typography from '@mui/material/Typography';
var axios = require('axios');





const BestFlightDealInfo = [
    { place: 'Goa', country: 'India', images: 'https://assets.wego.com/image/upload/h_296,c_fill,f_auto,fl_lossy,q_auto:best/v21012019/destinations/cities/GOI.jpg', iata_code: 'GOI' },
    { place: 'Varanasi', country: 'India', images: 'https://assets.wego.com/image/upload/h_296,c_fill,f_auto,fl_lossy,q_auto:best/v21012019/destinations/cities/VNS.jpg', iata_code: 'VNS' },
    { place: 'Jaipur', country: 'India', images: 'https://assets.wego.com/image/upload/h_296,c_fill,f_auto,fl_lossy,q_auto:best/v21012019/destinations/cities/JAI.jpg', iata_code: 'JAI' },
    { place: 'Bangkok', country: 'Thailand', images: 'https://assets.wego.com/image/upload/h_296,c_fill,f_auto,fl_lossy,q_auto:best/v21012019/destinations/cities/BKK.jpg', iata_code: 'BKK' },
    { place: 'Hongkong', country: 'China', images: 'https://assets.wego.com/image/upload/h_296,c_fill,f_auto,fl_lossy,q_auto:best/v21012019/destinations/cities/HKG.jpg', iata_code: 'HKG' },
    { place: 'Paris', country: 'France', images: 'https://assets.wego.com/image/upload/h_296,c_fill,f_auto,fl_lossy,q_auto:best/v21012019/destinations/cities/PAR.jpg', iata_code: 'ORY' },
    { place: 'Agra', country: 'India', images: 'https://assets.wego.com/image/upload/h_296,c_fill,f_auto,fl_lossy,q_auto:best/v21012019/destinations/cities/AGR.jpg', iata_code: 'AGR' },
    { place: 'kathmandu', country: 'Nepal', images: 'https://assets.wego.com/image/upload/h_296,c_fill,f_auto,fl_lossy,q_auto:best/v21012019/destinations/cities/KTM.jpg', iata_code: 'KTM' },
];

const CheapFlights = (props) => {
    const [location, setLocation] = useState([]);
    let Iplocation = [];
    useEffect(async () => {
        Iplocation = await axios.get("https://api.ipgeolocation.io/ipgeo?apiKey=3c12e3aead874277893299ff7e55ff9e");
        setLocation(Iplocation.data.city);
    }, [])

    const BestFlightDealsHandler = (toData, ClickStatus) => {
      //   console.log(toData);
       //  console.log(ClickStatus);
         props.getToData(toData, ClickStatus);


        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let fseSearch_h1 = document.querySelector('.fseSearch h1');
        let fseheader = document.querySelector('.fseheader');
        let fselogo_a = document.querySelector('.fselogo a');
        let fseSearch = document.querySelector('.fseSearch');
        let searchFlights = document.querySelector('.searchFlights');
        let tipsInformation = document.querySelector('.tipsInformation')
        fseSearch_h1.style.marginTop = '0px';
        fseSearch_h1.style.marginBottom = '0px';
        fseSearch_h1.style.visibility = 'hidden';
        fseheader.style.backgroundColor = '#ffffff';
       // fseheader.style.boxShadow = 'rgb(0 0 0 / 16%) 0px 1px 4px';
        fseSearch.style.height = 'auto';
        fseSearch.style.display = 'block';
        fseSearch.style.minHeight = 'auto';
        fseSearch.style.backgroundImage = 'linear-gradient(#fff,#F5F5FA)';
        fselogo_a.style.color = '#000000';
        searchFlights.style.backgroundImage = '';
        tipsInformation.style.display = 'none';

    }
   // console.log(location)


    return (
        <div className="cheapFlights" >
            <div className='container-fluid bg-fb'>
                <div className='container'>
                    <div className='row mt-5 pt-5 align-items-end'>
                        <div className='col-md-12'>
                            <h2>The best flight deals from <span style={{ color: '#f8a01c' }}>{location}</span></h2>
                            <p>Get back out there for less with the lowest fares we’ve found this week.</p>
                        </div>
                        <div className='col-md-4 text-end pe-5'>
                           
                        </div>


                    </div>
                    <div className='pb-5'>

                        <div className="row justify-content-between mt-4">
                            {BestFlightDealInfo.map((item, index) => {
                                return (
                                    <div className='col-md-3 mb-4 dealsCart' onClick={() => { BestFlightDealsHandler({ 'place': item.place, 'iata_code': item.iata_code, 'country': item.country }, true) }} id={item.place} value={item.place}>
                                        <Card elevation={3} className='dealsCartBody'>
                                            <CardActionArea>

                                                <CardMedia
                                                    component="img"
                                                    height="194"
                                                    image={item.images} value={item.place} />

                                                <CardContent>
                                                    <div className='d-flex align-items-center mb-2' value={item.place}>
                                                        <div className="fightImage me-3">
                                                            <FlightTakeoffIcon fontSize="medium" />
                                                        </div>
                                                        <div className="fightcontent">
                                                            <Typography variant="p" className='text-dark' sx={{ fontWeight: 600 }} component="div">
                                                                {item.place}
                                                            </Typography>
                                                            <Typography color="text.secondary">
                                                                <small> {item.country}</small>
                                                            </Typography>
                                                        </div>

                                                    </div>




                                                </CardContent>

                                            </CardActionArea>
                                        </Card>
                                    </div>)
                            })}


                        </div>








                    </div>

                </div>
            </div>



        </div>



    )

}


export default CheapFlights;
