import * as React from "react";
import "./App.css";

import FlightsResults from "./FlightsResults";
import CardInformation from "./CardInformation";
import CheapFlights from "./CheapFlights";
import Footer from "./Footer";
import Header from "./Header";
import { useEffect, useState, useRef, createContext, useContext } from "react";
import DataInput from "./DataInput";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
// import FlightsResults from './FlightsResults';
import CircularProgress from "@mui/material/CircularProgress";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
// fliter accordian Imports
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import CurrencycodeContext from "./CurrencycodeContext";
import Currencyconvert from "./Currencyconvert";
import EditedCountryCurrCodeContext from "./EditedCountryCurrCodeContext";
import Cookies from "js-cookie";

// import Button from '@mui/material/Button';
// import { GlobalData } from "./Context";
// Dialog modal popup

const Home = (props) => {
  const { item } = useContext(CurrencycodeContext);
//  console.log(item);
  // console.log(globalData);
  // useEffect(() => {
  //   let changedData = {
  //     data: 'changed'
  //   }
  //   setAPIData(Object.assign(APIData, changedData));
  //   console.log(APIData);
  // }, [])

  // Object.assign(target, source);
  const [triptype, setTriptype] = useState();
  const [cabintype, setCabintype] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [departtime, setDeparttime] = useState();
  const [onewayDeparttime, setonewayDeparttime] = useState();
  const [returntime, setReturntime] = useState();
  const [travelAdult, setTravelAdult] = useState();
  const [travelChildren, setTravelChildren] = useState();
  const [checksearchInfo, setchecksearchInfo] = useState(false);
  const [routesDetails, setroutesDetails] = useState([]);
  // const [loader, setLoader] = useState();
  const [flightapi, setFlightapi] = useState([null]);
  const [flightapiStatus, setflightapiStatus] = useState(false);
  const [errormsg, seterrormsg] = useState("");
  const [errorStatus, seterrorStatus] = useState(false);
  const [onclickerrormsg, setOnclickerrormsg] = useState("");
  const [onclickerrorStatus, setOnclickerrorStatus] = useState(false);
  const [loader, setLoader] = useState();
  const [action, setAction] = useState(false);
  const [dataCount, setDataCount] = useState();
  // fliter accrodian consts and useState
  const minDistance = 5;
  const [expanded, setExpanded] = useState("panel1");
  const [expanded2, setExpanded2] = useState("panel2");
  const [expanded3, setExpanded3] = useState("panel3");
  const [expanded4, setExpanded4] = useState("panel4");
  const [sortResults, setSortResults] = useState();
  const [currencyCookie, setcurrencyCookie] = useState([]);
  let isRegionalSettingsaved = false;
  const [isRegSettSaved, setisRegSettSaved]= useState();
 
  
  const [returnvalue1, returnsetValue1] = useState([0, 37]); // arrival or Return

  const [stop, setStop] = useState({
    DIRECT: "DIRECT",
    ONE_STOP: "ONE_STOP",
    TWO_STOPS: "TWO_STOPS",
  });
  const { DIRECT, ONE_STOP, TWO_STOPS } = stop;
  const [Airline, setAirline] = useState();
  let duration = 1720;
  let durMath = duration / 60;

  // let durationBySec = durMath.toString().slice(0, 2) // seconds
  // //console.log(durationBySec)
  let minPrice, maxPrice, minDuration, maxDuration;

  const [minprice, setMinprice] = useState();
  const [maxprice, setMaxprice] = useState();

  const [minDur, setminDur] = useState();
  const [maxDur, setmaxDur] = useState();

  const [valuePrice, setvaluePrice] = useState([]);

  const [minMaxDuration, setminMaxDuration] = useState([]);

  const [priceValueStatus, setPriceValueStatus] = useState(false);

  const [timeconvertmin, setTimeconvertmin] = useState([]);
  const [timeconvertmax, setTimeconvertmax] = useState([]);

  // const [loader, setLoader] = useState(false);

  // Dialog Modal popup
  const [open, setOpen] = useState(false);
 
  let RegcountryCode = document.getElementById("RegcountryCode");
  let regCurrencySet = document.getElementById("regCurrencySet");
  let countryNameOnload;
  let countryCodeOnload;  
  let CurrencyOnload;
  let symbolOnload;
  let getBothNameCode;
  let getBothcurrSymbol;
  let mergeOnloadRegArray = [];
  let regArray = [];
  // mergeOnloadRegArray = [getBothNameCode, getBothcurrSymbol];
  // console.log(mergeOnloadRegArray);

 useEffect(() => {
  getCurrencyDataByRegional('onload');
 }, [item]);
  
     
  
  // let mergeOnloadRegArray = [getBothNameCode, getBothcurrSymbol];
  

  const getCurrencyDataByRegional = (type) => {
     //console.log(type);
     if(type === 'onload') {
      //  console.log(item);
        countryNameOnload = item.length != 0 ? item.data.country_name : "";
        countryCodeOnload = item.length != 0 ? item.data.country_code2 : "";
        CurrencyOnload = item.length != 0 ? item.data.currency.code : "";
        symbolOnload = item.length != 0 ? item.data.currency.symbol : "";
        getBothNameCode = item.length != 0 ? countryNameOnload + " - " + countryCodeOnload : "";
        getBothcurrSymbol = item.length != 0 ? CurrencyOnload + " - " + symbolOnload : "";
        mergeOnloadRegArray = [getBothNameCode, getBothcurrSymbol];
        getFromCookie();
      //  console.log(currencyCookie);
        if(currencyCookie === undefined) {
          mergeOnloadRegArray = JSON.stringify(mergeOnloadRegArray);
          Cookies.set("currencyData", mergeOnloadRegArray, { expires: 7 });
          getFromCookie();
        }

     } else if(type === 'onclick') {
      RegcountryCode = RegcountryCode.value;
      regCurrencySet = regCurrencySet.value;
      regArray = [RegcountryCode, regCurrencySet];
      regArray = JSON.stringify(regArray);
      Cookies.set("currencyData", regArray, { expires: 7 }); // 'expires' sets the cookie expiration time in days
      isRegionalSettingsaved = true;
      getFromCookie();
       
     }
  };

  const getFromCookie = () => {
    let dataFromCookie = Cookies.get("currencyData");
   // console.log(dataFromCookie);
    setcurrencyCookie(dataFromCookie, isRegionalSettingsaved);
    
     // console.log(isRegionalSettingsaved);
     setisRegSettSaved(isRegionalSettingsaved)
  };
 
 
  const handleClose = () => {
    setOpen(false);
  };
  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // {
  //   flightDetails.map((data, index) => {
  //     console.log(data.name)
  //   })
  // }

  // console.log(ArrayDemoset);
  useEffect(() => {
    const timer = setInterval(() => {}, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const saveFlightDataHandler = (enteredFlightData, statusCheck, loading) => {
    // console.log(enteredFlightData);

    //  filteredData_Stops = enteredFlightData.legs.filter(onlyLegsData => onlyLegsData.stopoverCode == 'ONE_STOP');
    //  console.log(filteredData_Stops);
    // alert(statusCheck);
    // alert(loading);
    setFlightapi(enteredFlightData);
    setflightapiStatus(statusCheck);
    setLoader(loading);
    setAirline(enteredFlightData.airlines);
  };
  const saveNearbyAirportHandler = (Nearby) => {
    //console.log(Nearby)
  };
  // const getAirlineStatus = (status) => {
  //       console.log(status);
  // }
  useEffect(() => {
    if (flightapiStatus) {
      // console.log(flightapi)
      // alert(flightapiStatus)

    //   let filterData = flightapi.filters;
    //   let DataCount = flightapi.count;
    //   setDataCount(DataCount);
    //   // console.log(flightapi.legs.length);
    //   minPrice = filterData.minPrice.totalAmount;
    //   maxPrice = filterData.maxPrice.totalAmount;

    //   minDuration = filterData.tripDurations.min;
    //   maxDuration = filterData.tripDurations.max;
    // } else {
      // alert(flightapiStatus)
    }

    // setvaluePrice([minPrice, maxPrice]);

    // setminMaxDuration([minDuration, maxDuration]);

    let a = minDuration;
    let b = maxDuration;
    function timeConvert(a) {
      var num = a;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + " hour " + rminutes + " min";
    }

    setTimeconvertmin(timeConvert(a));
    // max duratiojn
    function timeConvertmax(b) {
      var num = b;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + " hour " + rminutes + " min";
    }

    // setTimeconvertmax(timeConvertmax(b));

    // setMinprice(minPrice);
    // setMaxprice(maxPrice);

    // setminDur(minDuration);
    // setmaxDur(maxDuration);
  }, [flightapiStatus]);

  //console.log(minDur)
  //console.log(maxDur)
  //console.log(minPrice)
  //console.log(maxPrice)

  //Price slider

  const actionLoader = (a) => {
    // console.log(a);
    setAction(a);
    seterrorStatus(false);
    setOnclickerrorStatus(false);
    setflightapiStatus(false);
    if (seterrorStatus == true) {
      setAction(false);
    } else {
      setAction(a);
    }
  };
  const onSubmitHandler = (x, y) => {
    // console.log(x)
    // console.log(y)
    setOnclickerrormsg(x);
    setOnclickerrorStatus(y);
  };
  const errorDisplayhandler = (a, b) => {
    // console.log(a)
    // console.log(b)
    seterrormsg(a);
    seterrorStatus(b);
  };
  const displaySubmittedData = (searchInfo, checksearchInfo) => {
    // console.log(searchInfo);
    setchecksearchInfo(checksearchInfo);
    setTriptype(searchInfo.triptype);
    setCabintype(searchInfo.cabintype);

    setFrom(searchInfo.from);
    setTo(searchInfo.to);
    setDeparttime(searchInfo.departtime.convertFromDate);
    setonewayDeparttime(searchInfo.departtime.convertonewayDate);
    setReturntime(searchInfo.returntime);
    setTravelAdult(searchInfo.travellercount.adult);
    setTravelChildren(searchInfo.travellercount.children);
    setAirlineList1([]);
    setAirlineList2([]);
    setStop({});

    // flightsInfo = [{
    //   'from' : searchInfo.from,
    //   'to' : searchInfo.to
    // }]
    // setuseflightsInfo(flightsInfo);
  };
  //console.log(from)
  const flightsinfoPlace = {
    from: from,
    to: to,
    triptype: triptype,
  };
  // setuseflightsInfo(flightsinfoPlace)
  // console.log(flightsinfoPlace)
  // props.onSaveSubmittedData(previewData);
  useEffect(() => {}, [saveFlightDataHandler]);
  // if(checksearchInfo){
  //   let storeinfoMap = searchinfopreview.map((entered, index) => {
  //     <div key={index}>
  //     <h3>{entered.triptype}</h3>
  //     <h3>{entered.cabintype}</h3>
  //     <h3>{entered.from}</h3>
  //     <h3>{entered.to}</h3>
  //     <h3>{entered.departtime}</h3>
  //     <h3>{entered.returntime}</h3>
  //     <h3>{entered.travellercount.adult}</h3>
  //     <h3>{entered.travellercount.children}</h3>
  //     </div>
  //   })
  //   console.log(storeinfoMap);

  // }

  //  console.log(checksearchInfo);

  //  console.log(onewayDeparttime);

  // fliter accordian code

  // Departure filter starts
  // const handleChangeDeparture = (event, newValueDeparture, activeThumb) => {
  //   // console.log(newValueDeparture);
  //   setValueDeparture(newValueDeparture);

  // };
  // OutBound or Departure filter ends

  // price

  const handleChangePrice = (event, newValue) => {
    //console.log(newValue)
    setvaluePrice(newValue);
    setPriceValueStatus(true);
  };

  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      returnsetValue1([
        Math.min(newValue[0], returnvalue1[1] - minDistance),
        returnvalue1[1],
      ]);
    } else {
      returnsetValue1([
        returnvalue1[0],
        Math.max(newValue[1], returnvalue1[0] + minDistance),
      ]);
    }
  };

  // Return ends

  // Journey Duration starts
  const handleChangeDur = (event, newValue1) => {
    setminMaxDuration(newValue1);
    // console.log(newValue1);
    let a = newValue1[0];
    let b = newValue1[1];
    //  min duration
    function timeConvert(a) {
      var num = a;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + " hour " + rminutes + " min";
    }

    setTimeconvertmin(timeConvert(a));
    // max duratiojn
    function timeConvertmax(b) {
      var num = b;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + " hour " + rminutes + " min";
    }

    setTimeconvertmax(timeConvertmax(b));
    // console.log(position[0])
    // console.log(position[1])
    // const minute = Math.floor(position[0] / 60);

    // const secondLeft = Math.floor(position[1] / 60);
    // console.log('minute' + minute);
    // console.log('secondLeft' + secondLeft);
    // const secondLeft = newValue - minute * 60;
    // return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  };

  //console.log(minMaxDuration)
  //console.log(timeconvertmin)
  //console.log(timeconvertmax)
  // const handleInputChange = (event) => {
  //   setJourney(event.target.journey === '' ? '' : Number(event.target.journey));
  // };

  // const handleBlur = () => {
  //   if (journey < 0) {
  //     setJourney(0);
  //   } else if (journey > 100) {
  //     setJourney(100);
  //   }
  // };

  // Accordian expand
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeTime = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
  };
  const handleChangeDuration = (panel) => (event, isExpanded) => {
    setExpanded3(isExpanded ? panel : false);
  };
  const handleChangeAirlines = (panel) => (event, isExpanded) => {
    setExpanded4(isExpanded ? panel : false);
  };

  // stops
  // defaultChecked
  // var stopsStatus = false;
  // var direct, onestop, twostop;
  const stopsFilter = (event) => {
    setStop({
      ...stop,
      [event.target.name]: event.target.checked,
    });

    // console.log(direct);
  };
  // console.log(stop)

  //  Airline filter

  // var airlineListMerge;

  // const [airlineListNew, setairlineListNew]= useState([]);
  const [airlineList1, setAirlineList1] = useState([]);
  const [airlineList2, setAirlineList2] = useState([]);
  const [isfilterActivate, setIsfilterActivate] = useState(false);
  const Airlinefilter = (event) => {
    let airlineListNew = [
      {
        name: event.target.name,
        code: event.target.value,
        status: event.target.checked,
      },
    ];
    //console.log(event.target.checked)
    if (event.target.checked === true) {
      setAirlineList1([...airlineList1, ...airlineListNew]);
    } else {
      setAirlineList2([...airlineList2, ...airlineListNew]);
    }

    setIsfilterActivate(true);
    // A comparer used to determine if two entries are equal.
  };
  const [statusRes, setStatusRes] = useState();
  const filterlengthHandler = (status) => {
    setStatusRes(status);
  };
  useEffect(() => {
    if (statusRes) {
      // alert("True")
    } else {
      setAirlineList1([]);
      setAirlineList2([]);
      // alert("False")
    }
  }, [statusRes]);

  //console.log(airlineList1, airlineList2)
  const [TodataPushToDatainput, setTodataPushToDatainput] = useState();
  const [TodataPushToDatainputStatus, setTodataPushToDatainputStatus] =
    useState();
  const getToDataHandler = (a, b) => {
    if (b === true) {
      // console.log(a)
      //  console.log(b)
      setTodataPushToDatainput(a);
      setTodataPushToDatainputStatus(b);
    }
  };
  const returnStatusCheckHandler = (x) => {
    setTodataPushToDatainputStatus(x);
  };
  let boolean = true;
  const filterMenuMobile = () => {
    if (boolean) {
      document.getElementById("hideINTabMob").style.display = "block";
      boolean = false;
    } else {
      document.getElementById("hideINTabMob").style.display = "none";
      boolean = true;
    }

    // alert("working")
  };
  var SearchSectionhideShow = true;
  const showSearchOption = (e) => {
    let fseSearch = document.querySelector(".fseSearch");
    if (SearchSectionhideShow === true) {
      fseSearch.style.display = "block";
      SearchSectionhideShow = false;
    } else {
      fseSearch.style.display = "none";
      SearchSectionhideShow = true;
    }
  };

  return (
    <div className="">
      <Header></Header>

      <div className="fseSearch">
        <div className="container">
          <h1>Best flights everywhere, from anywhere </h1>
          <DataInput
            onSaveSubmittedData={displaySubmittedData}
            onSaveFlightApiData={saveFlightDataHandler}
            onCollectLoader={actionLoader}
            errorName={errorDisplayhandler}
            ToStatusPass={returnStatusCheckHandler}
            onValidate={onSubmitHandler}
            getnearbyairport={saveNearbyAirportHandler}
            toData={TodataPushToDatainput}
            toDataStatus={TodataPushToDatainputStatus}
            setcurrData={currencyCookie}
            isregSetClick={isRegSettSaved}
          ></DataInput>
        </div>
      </div>
      <div className="previewDataSection">
        {checksearchInfo && (
          <div>
            <div className="container">
              <div className="rowCs">
                <div className="colinfo" onClick={showSearchOption}>
                  <span
                    title="Click to search the flights again..."
                    className="searchButtn"
                  >
                    <SearchSharpIcon className="searchIcon" />
                  </span>
                </div>
                <div className="colinfo">
                  
                  <div>TRIP TYPE</div>
                  {triptype === "roundtrip" ? "Round Trip" : "One Way"}
                </div>
                <div className="colinfo positionRel">
                  
                  <div>FROM</div>
                  {from}
                  <span className="SwapHorizIcon">
                    <SwapHorizIcon style={{ fontSize: "20px" }} />
                  </span>
                </div>
                <div className="colinfo">
                  
                  <div>TO</div>
                  {to}
                </div>
                {triptype === "roundtrip" ? (
                  <div className="colinfo">
                    
                    <div>DEPART</div>
                    {departtime}
                  </div>
                ) : (
                  <div className="colinfo">
                    
                    <div>DEPART</div>
                    {onewayDeparttime}
                  </div>
                )}
                {triptype === "roundtrip" ? (
                  <div className="colinfo">
                    
                    <div>RETURN</div>
                    {returntime}
                  </div>
                ) : (
                  ""
                )}
                <div className="colinfo">
                  
                  <div>PASSENGERS & CLASS</div>
                  {travelAdult > 0 && travelAdult + " " + "Adult,"}
                  {travelChildren > 0 && travelChildren + " " + "Children,"}
                  {cabintype}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* mobile version start here*/}

      {/* mobile version end here*/}
      <div className="container  bg-white">
        {action == true ? (
          <div>
            <div className="mobileFilter">
              <button
                type="button"
                onClick={filterMenuMobile}
                className="hiddenInDeskTop"
              >
                Filter
              </button>
            </div>
            <div className="row">
              <div className="col-md-3 " id="hideINTabMob">
                <div className="getPriceAlert">
                  {/*<Button className='mt-4 mb-4' variant="outlined"><NotificationsActiveIcon className='me-2' sx={{ fontSize: '14px' }} /> Get Price Alert</Button>*/}
                  <div className="verySmallFontSize mt-4 mb-4">
                     
                    {/* {flightapiStatus === true ? (
                      flightapi.legs.length
                    ) : (
                      <CircularProgress
                        color="secondary"
                        disableShrink
                        style={{ width: "20px", height: "20px" }}
                      />
                    )}  */}
                    results
                  </div>
                  <div>
                    {/* <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            flexShrink: 0,
                            fontWeight: "bold",
                            color: "#030d4e",
                          }}
                        >
                          Stops
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ fontSize: "12px" }}>
                        {flightapiStatus === true ? (
                          <FormGroup>
                            <FormControlLabel
                              sx={{ fontSize: "12px", color: "#030d4e" }}
                              control={
                                <Checkbox
                                  onChange={stopsFilter}
                                  name="DIRECT"
                                />
                              }
                              label="Direct"
                            />
                            <FormControlLabel
                              sx={{ fontSize: "12px", color: "#030d4e" }}
                              control={
                                <Checkbox
                                  onChange={stopsFilter}
                                  name="ONE_STOP"
                                />
                              }
                              label="1 stop"
                            />
                            <FormControlLabel
                              sx={{ fontSize: "12px", color: "#030d4e" }}
                              control={
                                <Checkbox
                                  onChange={stopsFilter}
                                  name="TWO_STOPS"
                                />
                              }
                              label="2+ stops"
                            />
                          </FormGroup>
                        ) : (
                          <CircularProgress
                            color="secondary"
                            disableShrink
                            style={{
                              textAlign: "center",
                              margin: "auto",
                              display: "flex",
                            }}
                          />
                        )}
                      </AccordionDetails>
                    </Accordion> */}
                    {/* <Accordion
                      expanded={expanded2 === "panel2"}
                      onChange={handleChangeTime("panel2")}
                      className="pb-3"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            flexShrink: 0,
                            fontWeight: "bold",
                            color: "#030d4e",
                          }}
                        >
                          Price
                        </Typography>
                      </AccordionSummary>
                      {flightapiStatus === true ? (
                        <AccordionDetails sx={{ position: "relative" }}>
                        <div className="d-flex typoStyle">
                          <Typography className="text-start"
                            sx={{ fontSize: "12px", color: "#030d4e" }}
                          >
                            Min Price : {valuePrice[0]}
                          </Typography>
                          <Typography className="text-end"
                            sx={{
                              fontSize: "12px",
                              color: "#030d4e",
                            }}
                          >
                            Max Price : {valuePrice[1]}
                          </Typography>
                          </div>
                          <Slider
                            // getAriaLabel={() => 'Minimum Price'}

                            value={valuePrice}
                            onChange={handleChangePrice}
                            valueLabelDisplay="auto"
                            // getAriaValueText={valuetext}
                            min={minprice}
                            max={maxprice}
                            step={10}
                            disableSwap
                            sx={{ color: "rgb(91, 38, 216)" }}
                          />
                        </AccordionDetails>
                      ) : (
                        <CircularProgress
                          color="secondary"
                          disableShrink
                          style={{
                            textAlign: "center",
                            margin: "auto",
                            display: "flex",
                          }}
                        />
                      )}
                    </Accordion> */}

                    {/* <Accordion
                      expanded={expanded3 === "panel3"}
                      onChange={handleChangeDuration("panel3")}
                      className="pb-3"
                    >
                    
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                      >
                      
                        <Typography
                          sx={{
                            fontSize: "14px",
                            flexShrink: 0,
                            fontWeight: "bold",
                            color: "#030d4e",
                          }}
                        >
                          Journey Duration
                        </Typography>
                      </AccordionSummary>
                      {flightapiStatus === true ? (
                        <div> 
                       
                          <AccordionDetails sx={{ position: "relative" }}>
                          <div className="d-flex typoStyle">
                            <Typography sx={{ fontSize: "12px", textAlign: 'left' }}>
                              Min: {timeconvertmin}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: "#030d4e",
                                textAlign: 'right' 
                              }}
                            >
                              Max: {timeconvertmax}
                            </Typography>
                            </div>
                            <Slider
                              value={minMaxDuration}
                              onChange={handleChangeDur}
                              // valueLabelDisplay="auto"
                              aria-label="Time"
                              step={10}
                              min={minDur}
                              max={maxDur}
                              disableSwap
                              sx={{ color: "rgb(91, 38, 216)" }}
                            />
                          </AccordionDetails>
                        </div>
                      ) : (
                        <CircularProgress
                          color="secondary"
                          disableShrink
                          style={{
                            textAlign: "center",
                            margin: "auto",
                            display: "flex",
                          }}
                        />
                      )}
                    </Accordion> */}
                    {/* <Accordion
                      expanded={expanded4 === "panel4"}
                      onChange={handleChangeAirlines("panel4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            flexShrink: 0,
                            fontWeight: "bold",
                            color: "#030d4e",
                          }}
                        >
                          Airlines
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {
                          // <div><Button className="size12" color="secondary" disabled>Select all</Button> | <Button color="secondary" className="size12">Clear all</Button></div>
                        }
                        <FormGroup className="pb-3">
                          {flightapiStatus === true ? (
                            <div>
                              {flightapi.airlines.map((air, index) => {
                                return (
                                  <div key={index}>
                                    <FormControlLabel
                                      className="size12"
                                      control={
                                        <Checkbox
                                          name={air.name}
                                          onChange={Airlinefilter}
                                          value={air.code}
                                        />
                                      }
                                      label={air.name}
                                      value={air.code}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <CircularProgress
                              color="secondary"
                              disableShrink
                              style={{
                                textAlign: "center",
                                margin: "auto",
                                display: "flex",
                              }}
                            />
                          )}
                        </FormGroup>
                      </AccordionDetails>
                    </Accordion> */}
                  </div>
                </div>
              </div>
              <div className="col-md-9 paddAdjustment">
                
                <div>
                  {onclickerrorStatus == true || errorStatus == true ? (
                    <div className="errorMsg">
                      Please enter a valid entry or {errormsg}
                    </div>
                  ) : (
                    <div>
                      {flightapiStatus === true ? (
                        <FlightsResults
                          results={flightapi}
                          flightPlaces={flightsinfoPlace}
                          stopsResults={stop}
                          priceFilter={valuePrice}
                          valueFilterStaus={priceValueStatus}
                          durationFilter={minMaxDuration}
                          checkedAirlines={airlineList1}
                          unCheckedAirlines={airlineList2}
                          isfilterStatus={isfilterActivate}
                          passAirlinefilterlengthStatus={filterlengthHandler}
                          sortResult={sortResults}
                        />
                      ) : (
                        <center>
                          <div className="loaderParent">
                            <div className="loader"></div>
                          </div>
                          <h3>Hold on, we’re fetching flights for you...</h3>
                        </center>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <span></span>
        )}
      </div>

      <CardInformation></CardInformation>
      <CheapFlights getToData={getToDataHandler}></CheapFlights>
      <div
        className="modal fade posirelative"
        id="Currency"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ position: "absolute", width: "100px", right: 0 }}
            >
              <button
                type="button"
                className="btn-close popupheadercustom"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Currencyconvert setcurrData={currencyCookie} />
              <div className="searchButton1">
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => getCurrencyDataByRegional('onclick')}
                  className="searchButtonInside"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
       
      <Footer></Footer>
    </div>
  );
};

export default Home;
