import React from 'react'
import Header from './Header';
import './Myaccount.css';
import LoginContext from './LoginContext';
import { useContext } from 'react';

const MyAccount = () => {
    const { item } = useContext(LoginContext);
    // console.log(item)
  return (
    <div>
    <Header></Header>
      <div>
      {
        item ? <h1>Welcome, {item.name}</h1> : <h2>Logged out</h2>
      }
      
      
      </div>
      
    </div>
  )
}

export default MyAccount
