import { createContext, useState} from "react";

const LoginContext = createContext();

export function LoginProvider({children}){
    const [item, setItem] = useState();

    const getLoginId = (id) => {
        setItem(id)
    }
     
    return (
       <LoginContext.Provider value={{item, getLoginId}}>{children}</LoginContext.Provider>
    );
}


export default LoginContext;
 
