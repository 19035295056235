import { createContext, useState} from "react";

const PostContext = createContext();

export function PostProvider({children}){
    const [item, setItem] = useState();

    const getPostId = (id) => {
        // console.log(id);
        setItem(id)
    } 

    return (
       <PostContext.Provider value={{item, getPostId}}>{children}</PostContext.Provider>
    );
}


export default PostContext;