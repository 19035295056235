import React, {useEffect, useState} from 'react';
import Blogsheader from './Blogsheader';
import './Search.css';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import {db} from "./firebase-config";
import { addDoc, collection } from 'firebase/firestore';
import axios from 'axios';
const Search = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState();
  const [searchDate, setSearchDate] = useState();
  const searchCollectionRef = collection(db, "wundrfly");
  const [items, setItems] = useState([]);
  const postSearch = async(value, date) => {
    console.log(value, date);
  
    // await addDoc(searchCollectionRef, {
    //  terms:  value,
    //  Date: date
    // });
    try{
      const response = await axios.post('https://server.taskticks.com/api/items/wundrfly', {
      terms:  value,
      Date: date
      });
      setItems([...items, response.data]);
    } catch (error) {
      console.error(error);
    }
  }

  const SearchYahooPage = (event) => {
      
    //  console.log(event);
      if(event.key === 'Enter') {
       // console.log("enter works");
       let searchYahoo = document.getElementById('searchYahooPage');
       // console.log(searchYahoo.value);
        let SYvalue = searchYahoo.value;
        let SearchedDate = new Date();
        postSearch(SYvalue, SearchedDate);
        // alert("Searchpage");
        // return false;
        
        navigate('/search?q='+ SYvalue);
        // console.log(queryString);
        const url = `/search?q=${SYvalue}`;
         window.history.pushState(null, null, url);
         let passLink = 'http://yssads.ddc.com/yhs.php?c=23572&surl=https://wundrfly.com&kw='+ SYvalue;
       // console.log(passLink);
        setTimeout(() => {
         //  navigate(passLink, { replace: true });
          window.open(passLink, '_target', 'noreferrer');
        }, 500)
        
        
      }
      
    }
    const enteredSearch = (e) => {
      setSearchValue(e.target.value);
      console.log(e.target.value);
    }

    useEffect(()=> {
     
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const qValue = urlParams.get('q');
      setSearchValue(qValue);
    }, [])
  return (
    <div>
    <Blogsheader />
    <div className='container pt-5'>
    
    
    <div className='row'>
    <div className='col-md-12 heightAd'>
     
     <div className='searchSection'>
      <div className='searchNameLogo'>
        <img src='./logo2.png'></img> <small>Search</small>
       
      </div>
      <div className='searchInputData'>
       <input onKeyDown={SearchYahooPage} onChange={enteredSearch} type='text' placeholder='Search the web' id='searchYahooPage' value={searchValue} className='searchpageInput'></input>
       </div>
     </div>
     
     </div>
     
    </div>
    </div>
      <Footer/>
    </div>
  )
}

export default Search
